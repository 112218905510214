import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]
  useEffect(() => {
    setWord(
      selectRandomItem(
        data.wordsJson.spanish.nouns.concat(data.wordsJson.spanish.adjectives)
      )
    )
  }, [data.wordsJson.spanish.nouns, data.wordsJson.spanish.adjectives])

  return (
    <BaseLayout>
      <SEO
        title="Generador de palabras aleatorias"
        titleSeo="Generador de sustantivos aleatorias"
        description="Herramienta para generar palabras al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Palabras', url: '/palabras/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {word}
            </Text>
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de palabras al azar en español
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() =>
                setWord(
                  selectRandomItem(
                    data.wordsJson.spanish.nouns.concat(
                      data.wordsJson.spanish.adjectives
                    )
                  )
                )
              }
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de palabras aleatorias</Heading1>
        <Paragraph>
          Un generador de palabras <em>random</em> en espa&ntilde;ol es una
          herramienta tan simple como &uacute;til: si quieres obtener una
          palabra completamente al azar simplemente tienes que darle al
          bot&oacute;n y una nueva palabra aleatorio aparecer&aacute; al
          instante.
        </Paragraph>
        <Paragraph>
          Este generador de palabras al azar es simple y tiene un
          prop&oacute;sito conciso, por lo que no deber&aacute;s preocuparte por
          conseguir un listado de palabras, por filtrarlo o por ajustar nada,
          simplemente tienes que tocar (o darle clic) al bot&oacute;n para
          generar una nueva palabra en espa&ntilde;ol seleccionada de forma
          aleatoria de entre un listado de miles de &iacute;tems disponibles.
        </Paragraph>
        <Heading2>
          Para qu&eacute; utilizar las palabras generadas aleatoriamente
        </Heading2>
        <Paragraph>
          Existen muchos usos para una herramienta tan simple, tantos que son
          pr&aacute;cticamente infinitos, pero a modo de ejemplo podemos
          mencionar algunos juegos de mesa que requieren el uso de palabras que
          deben ser necesariamente aleatorios, porque de ser escogidas a dedo
          por alg&uacute;n participante, entonces resultar&iacute;a en una
          ventaja injusta.
        </Paragraph>
        <Paragraph>
          En otras ocasiones es posible que simplemente necesitemos algo de
          inspiraci&oacute;n y partir de una palabra generada de forma aleatoria
          puede venirnos muy bien como punto de partida. Al ser tan poco, una
          simple palabra no nos ata a una idea o escenario espec&iacute;fico,
          sino que nos permite imaginar su contexto a nuestro gusto, trayendo a
          su vez variedad, porque al no estar en control de la lista de palabras
          disponibles ni de la selecci&oacute;n de una en particular, nos
          libramos de nuestros sesgos como lo son nuestras palabras favoritas o
          aquellas que escuchamos o leemos con m&aacute;s frecuencia.
        </Paragraph>
        <Paragraph>
          Para tareas donde se vea involucrada la escritura o la
          narraci&oacute;n, este generador de palabras aleatorias puede
          resultarnos una herramienta muy &uacute;til porque r&aacute;pidamente
          podemos analizar muchas opciones distintas y escoger alguna en
          particular que nos interese, no tenemos por qu&eacute; limitarnos a la
          primera que aparezca, aunque si queremos un desaf&iacute;o extra,
          perfectamente podemos hacerlo.
        </Paragraph>
        <Heading3>Un esfuerzo que te ahorras</Heading3>
        <Paragraph>
          Por muy simple que pueda parecer, el formar una lista de palabras lo
          suficientemente grande y variada como para que la generaci&oacute;n
          pueda ser aleatoria e interesante, puede resultar ser una tarea
          tediosa que consuma nuestro tiempo de forma innecesaria.
        </Paragraph>
        <Paragraph>
          Es justamente por su simplicidad que este generador de palabras al
          azar online nos permite simplemente acceder desde cualquier
          dispositivo y obtener de inmediato tantas palabras como queramos, sin
          riesgo de mucha repetici&oacute;n ni esfuerzo alguno por nuestra
          parte; todo el trabajo ya est&aacute; hecho, lo &uacute;nico que
          quedar es presionar el bot&oacute;n y ver el resultado.
        </Paragraph>
        <Heading4>
          Palabras ya filtradas, aptas para cualquier edad y variadas en
          significado y contexto
        </Heading4>
        <Paragraph>
          Algo importante al utilizar una herramienta de generaci&oacute;n de
          palabras al azar es poder confiar en que las palabras ser&aacute;n
          aptas para cualquier contexto y edad, pero manteniendo su variedad.
        </Paragraph>
        <Paragraph>
          Es por eso que esta herramienta online incluye miles de palabras
          previamente filtradas y controladas de forma manual para que sean
          apropiadas para todos los p&uacute;blicos y adem&aacute;s provengan de
          toda clase de contextos diferentes, asegurando as&iacute; su seguridad
          de uso y la diversidad de resultados.
        </Paragraph>
        <Paragraph>
          Que se generen palabras random no significa que no haya un control
          sobre la lista de palabras desde la cual se parte para hacer la
          selecci&oacute;n aleatoria, sino simplemente que partiendo de un grupo
          de &iacute;tems controlados manualmente, el resultado de una
          generaci&oacute;n ser&aacute; aleatoria y no seguir&aacute; un orden o
          criterio en concreto, esto permite evitar sesgos en la elecci&oacute;n
          a la vez que mantener la calidad de los resultados generados.
        </Paragraph>
        <Heading4>Simplicidad y funcionalidad, ante todo</Heading4>
        <Paragraph>
          Puede parecer contra intuitivo, pero en el caso de herramientas como
          esta, el estar constantemente haciendo cambios y agregando/quitando
          funcionalidades resulta en una peor experiencia para los usuarios,
          dado que en lugar de poder simplemente acceder y comenzar a generar
          palabras aleatorias, la persona se encuentra con una aplicaci&oacute;n
          siempre cambiante y con ajustes innecesarios que entorpecen una tarea
          de tan simple naturaleza.
        </Paragraph>
        <Paragraph>
          Es por eso que este generador se mantiene simple y constante a lo
          largo del tiempo, sin producir grandes cambios a c&oacute;mo funciona
          la generaci&oacute;n de palabras en espa&ntilde;ol al azar, los
          par&aacute;metros utilizados, las opciones disponibles o la interfaz
          de usuario mediante la cual se presenta.
        </Paragraph>
        <Paragraph>
          Mantener su apariencia y funcionalidad simples y limitadas permite a
          este generador aleatorio el ser una herramienta confiable a la cual
          los usuarios pueden volver con confianza en que seguir&aacute;
          funcionando no solo tan bien como siempre, sino adem&aacute;s de la
          misma manera, sin necesidad de reaprender a utilizarla.
        </Paragraph>
        <Heading2>
          Funciona en todos los dispositivos, incluso sin internet
        </Heading2>
        <Paragraph>
          Este generador de palabras random en espa&ntilde;ol funciona en
          cualquier dispositivo actual que cuente con un navegador web, dado que
          simplemente se basa en la tecnolog&iacute;a de Javascript para
          realizar la generaci&oacute;n de palabras en espa&ntilde;ol al azar, a
          lo cual se le suma que, adem&aacute;s, luego del primer acceso
          podr&aacute;s guardar la web como una aplicaci&oacute;n (PWA o &ldquo;
          <em>Progressive Web App</em>&rdquo; en ingl&eacute;s) para acceder a
          ella directamente y sin necesidad de internet, ya que los archivos
          necesarios para su funcionamiento ya se habr&aacute;n descargado, sin
          necesidad alguna de instalaci&oacute;n.
        </Paragraph>
        <Paragraph>
          Sin importar cu&aacute;l sea tu navegador de preferencia: Google
          Chrome, Mozilla Firefox, Apple Safaria, Vivaldi, Brave, Opera, o
          cualquier otro, todos ellos soportan las tecnolog&iacute;as web
          actuales necesarias para acceder y hacer uso de este generador de
          palabras aleatorias online, y tanto en iOS (en el caso de iPhone y
          iPad) como en Android es posible guardar el acceso directo a la
          p&aacute;gina web como una aplicaci&oacute;n, que se abrir&aacute;
          como una aplicaci&oacute;n nativa (sin los elementos del navegador) y
          funcionar&aacute; correctamente incluso sin internet, sin requerir de
          una instalaci&oacute;n previa o del uso de las tiendas de aplicaciones
          de Google (Google Play Store) o Apple (App Store).
        </Paragraph>
        <Heading3>En constante actualizaci&oacute;n</Heading3>
        <Paragraph>
          Algo importante al momento de utilizar una herramienta online, como
          este generador de palabras, es que se mantenga igual (al menos a fines
          pr&aacute;cticos, m&aacute;s all&aacute; de cambios est&eacute;ticos)
          a lo largo del tiempo y uno pueda confiar en volver a visitarla y
          utilizarla con total confianza en que no solo seguir&aacute;
          funcionando como siempre, sino que adem&aacute;s mantendr&aacute;
          resultados frescos y variados.
        </Paragraph>
        <Paragraph>
          Ante la presencia de errores o problemas t&eacute;cnicos, uno puede
          tener la calma de saber que hay alguien trabajando en ello y se
          solucionar&aacute; pronto, por lo que el mantenimiento constante del
          estado de salud de la aplicaci&oacute;n resulta en una mejor
          experiencia de usuario.
        </Paragraph>
        <Paragraph>
          Si tienes dudas o comentarios acerca de alguna funcionalidad en
          particular, o te has encontrado con alg&uacute;n problema, no dudes en
          comunicarte con nosotros mediante la secci&oacute;n de comentarios o
          el formulario de la p&aacute;gina de contacto.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
        adjectives
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
